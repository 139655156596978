import headshot from "../assets/Headshot RE 2023 sized.jpg";

const Bio = ({ }) => {
    return (
        <section className="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20 !pt-20 pb-0 md:pb-16 md:!pt-24">
            <div className="container max-w-screen-lg flex flex-col md:flex-row items-stretch min-h-max h-max">
                <div className="bg-cover bg-bottom w-full md:w-2/5 mb-4 md:mb-0">
                    <img src={headshot} className="object-cover w-full h-full object-cover" />

                </div>
                <div className="flex flex-col justify-center w-full md:w-3/5 md:pl-16">
                    <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>MEET YOUR TEAM

                    </span></h2>

                    <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">Licensed, expert real estate agents

                    </h6>
                    <div>
                        <p>If attention to detail and superior customer service are the most important goals, Ashley is the agent for
                            you. Her unique ability to pair the specific needs of her clients with the perfect property is unmatched
                            in the leasing industry.</p>
                        <p>
                            Finding the right apartment can be an overwhelming task, but Ashley has a passion for finding the
                            perfect place at the perfect time and will always get the best deal possible. She is in constant contact
                            with her affiliated properties (and always adding new ones), so she is well informed about the special
                            offers, amenities offered, bonus deals and availability. She is very familiar with properties,
                            neighborhoods, restaurants, entertainment, exercise opportunities and just about anything that may be
                            important to her clients and factors these interests into finding the right community before presenting
                            them with the best options.
                        </p>
                        <p>
                            Once arriving in Denver, Ashley decided to specialize in apartment locating and has placed hundreds of
                            happy clients in their perfect apartments (many of whom return to her when their lease is up or when
                            deciding to purchase a home!)
                        </p>
                        <p>
                            Ashley has lived in Pennsylvania, Florida and Texas, but found her true home in beautiful Denver,
                            Colorado. A licensed realtor since 2015, Ashley is passionate about seamlessly guiding her clients
                            through the process of locating the perfect home and loves to build relationships along the way. As a
                            leader in the industry, she has now built a new apartment locator division within Modus Real Estate and
                            continues to create and implement innovative strategies to streamline the process of leasing from the
                            first phone call to the move-in day and beyond.
                        </p>


                        <p>  With a passion for fitness and nature, Ashley enjoys running trails, biking, and Orange Theory Fitness.
                            She is supported at home by her amazing pups, Toast and Finn, and loves to visit many parks and
                            experience the beautiful outdoor spaces Colorado has to offer. You can find her all over town trying
                            different foodie spots (she lives for good food) and exploring all that this exciting and unique city has to
                            offer.

                        </p>
                        <p>
                            Ashley can’t wait to welcome you home!
                        </p>
                    </div>
                </div>


            </div>

        </section>
    )
}
export default Bio;
