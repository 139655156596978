

const Steps = ({ }) => {
    return (
        <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
            <div class="container max-w-screen-lg">
                <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>Here's How It Works</span></h2>
                <ul class="grid lg:grid-cols-4 sm:grid-cols-2 gap-5 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                    <li>
                        <div class="flex items-center my-5">

                            <div class="flex items-center justify-center w-100 h-16">
                                <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                    <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark underline  leading-snug md:leading-snug tracking-wider"><span>One</span></h3>
                                </h3>
                                {/* <svg width="100%" height="100%" viewBox="0 0 200 100">
                                    <text x="0" y="75" font-family="Poppins" font-size="90" text-decoration="underline">One</text>
                                </svg> */}

                                {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 182.23 247.6" class="fill-current w-16 h-16">
                                    <path d="M91.11,0v210h-5.7V5.7h-54V0H91.11z"></path>
                                    <rect y="242.6" width="182.23" height="5"></rect>
                                </svg> */}
                            </div>
                        </div>
                        <div>
                            <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">Meet Your Locator</h6>
                            <span>
                                Connect on a quick call with your Locator to discuss your unique search criteria. Whether you are new to Denver or just looking for a new space, we will find you the perfect home. We will make sure you get your must-haves and avoid your deal breakers. Want a balcony, pool, upscale gym, pet spa? We've got you covered!
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="flex items-center my-5">
                            <div class="flex items-center justify-center w-100 h-16">
                                <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                    <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark underline  leading-snug md:leading-snug tracking-wider"><span>Two</span></h3>
                                </h3>
                                {/* <svg width="100%" height="100%" viewBox="0 0 200 100">
                                    <text x="0" y="75" font-family="Poppins" font-size="90" text-decoration="underline">Two</text>
                                </svg> */}
                                {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 182.23 251.24" class="fill-current w-16 h-16">
                                    <path
                                        d="M161.17,205.2v5.7H20.76v-4.5l90.9-92.1c26.7-27,32.1-42.6,32.1-60.3c0-29.4-20.4-48.3-58.5-48.3 c-28.8,0-49.5,8.4-64.8,25.5l-4.2-4.2C31.86,9.6,54.67,0,85.27,0c39.6,0,64.5,20.4,64.5,53.7c0,19.8-6.6,36.9-33.9,64.5l-85.81,87 H161.17L161.17,205.2z"
                                    ></path>
                                    <rect y="246.24" width="182.23" height="5"></rect>
                                </svg> */}
                            </div>
                        </div>
                        <div>
                            <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">SIT BACK AND RELAX</h6>
                            <span>Your Locator will curate a personalized list of available options and present you with the best deals in Denver.
                                All searches are individualized to meet the needs of each client. No more guesswork!</span>
                        </div>
                    </li>
                    <li className="w-[250px]">
                        <div class="flex items-center my-5">
                            <div class="flex items-center justify-center w-100 h-16">
                                <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                    <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark underline  leading-snug md:leading-snug tracking-wider"><span>Three</span></h3>
                                </h3>
                                {/* <svg width="100%" height="100%" viewBox="0 0 300 100">
                                    <text x="0" y="75" font-family="Poppins" font-size="90" text-decoration="underline">Three</text>
                                </svg> */}
                                {/* <svg width="200" height="100" xmlns="http://www.w3.org/2000/svg">
                                    <text x="50" y="40" font-family="Poppins" font-size="20">3</text>
                                    <line x1="50" y1="45" x2="60" y2="45" stroke="black" stroke-width="2" />
                                </svg> */}
                                {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 182.23 245.02" class="fill-current w-16 h-16">
                                    <path
                                        d="M155.92,152.4c0,34.8-24.3,58.5-69.3,58.5c-31.5,0-59.4-12.3-72-29.7l3.9-3.9c11.7,15.6,37.2,27.9,68.1,27.9 c41.7,0,63.3-20.7,63.3-52.8s-21.3-52.8-67.2-52.8h-10.8v-4.5l68.1-89.4H21.22V0h126.9v4.5l-68.1,89.4h2.4 C131.92,93.9,155.92,117.6,155.92,152.4z"
                                    ></path>
                                    <rect y="240.02" width="182.23" height="5"></rect>
                                </svg> */}
                            </div>
                        </div>
                        <div>
                            <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">FINALIZE YOUR TOP PICKS</h6>
                            <span>Your Locator will review your Top Picks with you, share videos, and finalize your favorites.
                                Don't worry...we will give you the inside scoop for every community to ensure we find a perfect match. No wasted time touring communities that aren't a perfect fit!
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="flex items-center my-5">
                            <div class="flex items-center justify-center w-100 h-16">
                                <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                    <h3 class="uppercase text-3xl md:text-4xl font-light text-global-dark underline  leading-snug md:leading-snug tracking-wider"><span>Four</span></h3>
                                </h3>
                                {/* <span>Four</span> */}
                                {/* <svg width="100%" height="100%" viewBox="0 0 200 100">
                                    <text x="0" y="75" font-family="Poppins" font-size="90" text-decoration="underline">Four</text>
                                </svg> */}
                                {/* <svg width="200" height="100" xmlns="http://www.w3.org/2000/svg">
                                    <text x="50" y="20" font-family="Poppins" font-size="20">4</text>
                                    <line x1="50" y1="25" x2="80" y2="25" stroke="black" stroke-width="2" />
                                </svg> */}
                                {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 182.23 241.58" class="fill-current w-16 h-16">
                                    <path d="m174.3,147.6h-46.8v62.4h-6v-62.4H0v-4.5L118.2,0h7.2L8.1,141.9h113.4v-54.9h6v54.9h46.8v5.7h0Z"></path>
                                    <rect y="236.58" width="182.23" height="5"></rect>
                                </svg> */}
                            </div>
                        </div>
                        <div>
                            <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">TOUR WITH A PRO</h6>
                            <span>
                                Your Locator does all the work for you from scheduling your tours to providing your itinerary for the day. Just meet your Locator in the lobby of your first scheduled tour!
                            </span>
                        </div>
                    </li>
                </ul>
                <span class="mt-5 block text-sm text-gray-500 sm:text-center dark:text-gray-400">
                    * THIS IS A FREE SERVICE…THERE’S NO CATCH! We are fully compensated through our
                    partnerships and relationships with the apartment communities we have within our Network
                    (servicing Denver and the Greater Denver area). There is NO COST to our clients for utilizing our

                    services. Our agents MUST introduce you and tour with you in person in order to represent you
                    and receive compensation from our communities.</span>

            </div>
        </section>
    )
}

export default Steps;