import { ReactGoogleReviews } from "react-google-reviews";
import "react-google-reviews/dist/index.css";
// const key = PgiAWZmqeGYH3Adq8;
const Reviews = ({ }) => {
    const featurableWidgetId = "79d809a4-a8ec-4fb8-8685-fd247114cabb"; // You can use "example" for testing

    return (
        <section className="flex justify-center px-8 py-12 sm:px-6 md:px-8 md:py-16 lg:px-5 lg:py-20 !pt-20 pb-0 md:pb-16 md:!pt-24">
            <div className="container max-w-screen-lg  sm:w-50 flex flex-row  justify-center items-center min-h-max h-max">
                <ReactGoogleReviews layout="carousel" featurableId={featurableWidgetId} />

            </div>

        </section>
    )
}

export default Reviews;
