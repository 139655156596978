import headshot from "../assets/Kormick Chapman.png";

let SecondBio = ({ }) => {
    return (
        // <section className="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20 !pt-20 pb-0 md:pb-16 md:!pt-24">
        //     <div className="container max-w-screen-lg flex flex-col md:flex-row items-stretch min-h-max h-max">
        //         <div className="bg-cover bg-bottom w-full md:w-2/5 mb-4 md:mb-0">
        //             <img src={headshot} className="object-cover w-full h-full object-cover" />

        //         </div>
        //         <div className="flex flex-col justify-center w-full md:w-3/5 md:pl-16">
        //             <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>MEET YOUR TEAM

        //             </span></h2>

        //             <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">Licensed, expert real estate agents

        <section className="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20 !pt-20 pb-0 md:pb-16 md:!pt-24">
            <div className="container max-w-screen-lg flex flex-col md:flex-row items-stretch min-h-max h-max">
                <div className="bg-cover bg-bottom w-full md:w-2/5 mb-4 md:mb-0">
                    <img src={headshot} className="object-cover w-full h-full object-cover" />

                </div>
                <div className="flex flex-col justify-center w-full md:w-3/5 md:pl-16">
                    {/* <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                        <span>
                            MEET YOUR TEAM
                        </span>
                    </h2> */}
                    <h6 class="uppercase font-heading tracking-wider text-[18px] mb-2">
                        Kormick Chapman

                    </h6>
                    <div>
                        <p>
                            Looking for a seamless real estate experience with a focus on personalized service? Kormick Chapman is the agent who can make it happen. Known for his unmatched dedication to finding the right home for each client, Kormick brings a unique blend of expertise and passion to the table.
                        </p>


                        <p>

                            Navigating the apartment search can be challenging, but with Kormick on your side, the process becomes effortless. He constantly scouts the market, staying ahead of the latest trends, deals, and offerings. His deep understanding of Denver’s diverse neighborhoods, along with his knowledge of local amenities, ensures that every client finds a place that perfectly aligns with their lifestyle.
                        </p>

                        <p>

                            Kormick’s passion for real estate is deeply rooted in his Colorado heritage.Born in the mountain town of Steamboat Springs, he developed a love for the state's unique landscapes and communities from an early age. After spending time in Idaho, Kormick returned to Colorado over four years ago, bringing with him a wealth of experience and a fresh perspective. It wasn’t long after his return that he discovered his true calling in real estate. Since then, he has been dedicated to helping clients find their perfect homes, earning their trust and loyalty with each successful match.
                        </p>
                        <p>

                            Beyond real estate, Kormick is an avid outdoor enthusiast with a deep love for skiing and mountain biking.Whether he's carving through fresh powder or tackling challenging trails, Kormick finds his greatest joy in the Colorado outdoors. When he's not on the slopes or trails, you might find him planning his next travel adventure.Traveling whenever he can, Kormick enjoys discovering new places and experiences that broaden his horizons and inspire his work.
                        </p>
                        <p>

                            Kormick’s commitment to his clients goes beyond just finding them a home—he’s dedicated to building lasting relationships and ensuring they feel welcome and supported every step of the way.
                        </p>
                        <p>

                            With Kormick Chapman, your next home is just a step away.Let’s make it happen together!
                        </p>
                    </div>

                </div>

            </div>
        </section>

    )
}
export default SecondBio;