
const Info = () => {
    return (
        <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
            <div class="container max-w-screen-lg">
                <ul class="grid md:grid-cols-2 sm:grid-cols-2 gap-8 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                    <li>
                        <div class="flex items-center my-5">
                            <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>Deals</span></h2>

                        </div>

                        <div>
                            <span>We have the inside scoop on all the best deals in Denver from free parking to free rent incentives…if the
                                deal is out there, we are the first to know!</span>
                        </div>

                    </li>
                    <li>
                        <div class="	flex items-center my-5">
                            <h2 class="align-middle uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                <span>WE ARE LOCALS</span>
                            </h2>
                        </div>

                        <div>
                            <span>We are fully licensed real estate agents and live full-time in Denver. No call center involved here…We
                                know the ins and outs of the city (and everywhere in between!) We can help you find a new favorite
                                restaurant, coffee shop, hiking trail or the best place to walk your pup!</span>
                        </div>

                    </li>


                </ul>
            </div>
        </section>
    )


}

export default Info;
{/* 
                return (
                <>
                    <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                        <div class="container max-w-screen-lg">
                            <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>Deals</span></h2>
                            <span>We have the inside scoop on all the best deals in Denver from free parking to free rent incentives…if the
                                deal is out there, we are the first to know!</span>
                        </div>
                    </section>
                    <hr class="max-w-screen-lg mx-auto mb-7" />
                    <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                        <div class="container max-w-screen-lg">
                            <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>WE ARE LOCALS</span></h2>
                            <span>We are fully licensed real estate agents and live full-time in Denver. No call center involved here…We
                                know the ins and outs of the city (and everywhere in between!) We can help you find a new favorite
                                restaurant, coffee shop, hiking trail or the best place to walk your pup!</span>
                        </div>
                    </section>
                    <hr class="max-w-screen-lg mx-auto mb-7" />
                    <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                        <div class="container max-w-screen-lg">
                            <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>TRUST US TO DO THE WORK FOR YOU</span></h2>
                            <span>Take the stress out of the unknown. Let one of our agents who specializes in finding the perfect
                                community for each client do the work for you. Stop combing through random websites and stock
                                photos online. Our agents have relationships with our communities and frequent them daily, so they
                                know the floor plans, amenities and most of all, the BEST DEALS!</span>
                        </div>
                    </section>
                </>
                ) */}