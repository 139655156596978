import denver from "../assets/denver.png";
const Map = ({ }) => {
    return (
        <section className="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20 !pt-20 pb-0 md:pb-16 md:!pt-24">
            <div className="container max-w-screen-lg flex flex-row  justify-center items-center min-h-max h-max">

                <img src={denver} />

            </div>

        </section >

    )
}

export default Map;