import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import "react-google-reviews/dist/index.css";
import ReactGA from "react-ga4";

ReactGA.initialize("G-4Z6QXMEDMD");
function App() {
  return (
    <Home />
  );
}

export default App;
