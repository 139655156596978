import logo2 from "../assets/MODUS_AL_logo.png";

const NavBar = () => {
    return (
        <div id="apt-navbar-bar" class="fixed shrink-0 z-1030 transition w-full">
            <div class="flex justify-end items-center transition w-full ease-in px-5 md:px-4 h-[100px]">
                <div class="flex items-center text-white">
                    <span class="ml-2 md:ml-3">
                        <a class="flex items-center" aria-label="Modus Home Page" href="/">
                            <img class="bottom-0 left-0 logo-box w-48 md:w-280 lg:w-200 xl:w-240" src={logo2} />
                        </a>
                    </span>
                </div>
                <div id="apt-navbar-portal" class="flex-1 min-w-0"></div>
            </div>
        </div>
    )
}

export default NavBar;