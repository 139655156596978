import baker from "../assets/Baker.jpg";
import golden from "../assets/golden.jpg";
import lohi from "../assets/lohi.jpg";
import sunnyside from "../assets/Sunnyside.jpg";
import lakewood from "../assets/Lakewood.jpg";
import berkely from "../assets/Berkeley.jpg";

const PictureStep = ({ }) => {
    return (
        <section className="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20 !pt-20 pb-0 md:pb-16 md:!pt-24">
            <div className="container max-w-screen-lg flex flex-row  justify-center items-center min-h-max h-max">
                <div class="grid grid-cols-3 gap-4">
                    <div class="relative">
                        <img src={baker} alt="Baker" class="w-full h-48 object-cover" />
                        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center p-2">Baker</div>
                    </div>
                    <div class="relative">
                        <img src={berkely} alt="Berkeley" class="w-full h-48 object-cover" />
                        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center p-2">Berkeley</div>
                    </div>
                    <div class="relative">
                        <img src={sunnyside} alt="Sunnyside" class="w-full h-48 object-cover" />
                        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center p-2">Sunnyside</div>
                    </div>
                    <div class="relative">
                        <img src={lohi} alt="LoHi" class="w-full h-48 object-cover" />
                        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center p-2">LoHi</div>
                    </div>
                    <div class="relative">
                        <img src={lakewood} alt="Lakewood" class="w-full h-48 object-cover" />
                        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center p-2">Lakewood </div>
                    </div>
                    <div class="relative">
                        <img src={golden} alt="Golden" class="w-full h-48 object-cover" />
                        <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center p-2">Golden</div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default PictureStep;