
const Info3 = () => {
    return (
        <section class=" flex justify-center   px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
            <div class="container max-w-screen-lg">
                <ul class="grid md:grid-cols-2 sm:grid-cols-2 gap-8 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                    <li>
                        <div class="flex items-center my-5">
                            <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>TRUST US TO DO THE WORK FOR YOU</span></h2>
                            </h2>
                        </div>
                        <div>
                            <span>Take the stress out of the unknown. Let one of our agents who specializes in finding the perfect
                                community for each client do the work for you. Stop combing through random websites and stock
                                photos online. Our agents have relationships with our communities and frequent them daily, so they
                                know the floor plans, amenities and most of all, the BEST DEALS!</span>
                        </div>
                    </li>
                    <li>
                        <div class="flex items-center my-5">
                            <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>IT’S NOT JUST ABOUT THE APARTMENT</span></h2>
                            </h2>
                        </div>
                        <div>
                            <span>Our agents are not just concerned about finding the right apartment, your neighborhood is just as
                                important. This is our superpower! Our agents will connect you with the perfect location to support
                                your needs outside of your apartment home. Whether you prefer being in the suburbs or prefer
                                walkability…we’ve got you!</span>
                        </div>
                    </li>
                    {/* <li>
                        <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                            <div class="container max-w-screen-lg">
                                <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span></span></h2>
                                <span></span>
                            </div>
                        </section>
                    </li> */}
                </ul>
            </div>
        </section>
    )
}
export default Info3;