
const NextInfo = () => {
    return (
        <>
            <section class="flex justify-center   px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                <div class="container max-w-screen-lg">
                    <ul class="grid grid-cols gap-8 flex flex-col  md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                        <li>
                            <div class="flex items-center my-5">
                                <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  content-center leading-snug md:leading-snug tracking-wider"><span>UNABLE TO TOUR IN PERSON?</span></h2>

                            </div>

                            <div>
                                <span>Many of our satisfied clients have been placed while conducting the process 100% virtually. We can
                                    perform virtual tours, so you won’t miss a thing!</span>
                            </div>
                        </li>
                        {/* <li>
                            <div class="flex items-center my-5">
                                <h2 class="align-middle uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider">
                                    <span>WE ARE LOCALS</span>
                                </h2>
                            </div>

                            <div>
                                <span>We are fully licensed real estate agents and live full-time in Denver. No call center involved here…We
                                    know the ins and outs of the city (and everywhere in between!) We can help you find a new favorite
                                    restaurant, coffee shop, hiking trail or the best place to walk your pup!</span>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </section>

            {/* <hr class="max-w-screen-lg mx-auto mb-7" />
            <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                <div class="container max-w-screen-lg">
                    <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>UNABLE TO TOUR IN PERSON?</span></h2>
                    <span>Many of our satisfied clients have been placed while conducting the process 100% virtually. We can
                        perform virtual tours, so you won’t miss a thing!</span>
                </div>
            </section>
            <hr class="max-w-screen-lg mx-auto mb-7" />
            <section class="flex justify-center px-8 py-12 sm:px-10 md:px-12 md:py-16 lg:px-5 lg:py-20">
                <div class="container max-w-screen-lg">
                    <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark  leading-snug md:leading-snug tracking-wider"><span>MEET YOUR TEAM</span></h2>
                    <span>Licensed, expert real estate agents</span>
                </div>
            </section> */}
        </>
    )
}

export default NextInfo;